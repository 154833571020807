import React from "react"
import { Container, Button, Col, Media, Image, Row } from "react-bootstrap"
import "./styles/how-it-works.styles.scss"
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Layout/Footer"
import NavBarLight from "../components/Layout/navBar/NavBarLight"

const CcpaInfo = () => (
  <>
    <NavBarLight />

    <Container fluid className="home-container ccpa-information p-0  ">
      <Container fluid className="py-5 py-md-0">
        <Container fluid="xl" className="py-xl-6 d-flex flex-column ">
          <h1 className="h1-header" id="top">
            CCPA Information
          </h1>
          <Row>
            <Col md={8}>
              <h5>
                Salt Point Finance dba Pave Credit CCPA Privacy Notice Last
                updated 8/10/2021
              </h5>
              <p>
                Residents of California have additional rights under the
                California Consumer Privacy Act (“CCPA”).{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  Under the CCPA, California residents have the right to know
                  and access your personal information.{" "}
                </li>
                <li>
                  {" "}
                  Know the categories of personal information we collect and the
                  categories of sources from which we got the information;{" "}
                </li>
                <li>
                  {" "}
                  Know the business or commercial purposes for which we collect
                  and share personal information;{" "}
                </li>
                <li>
                  {" "}
                  Know the categories of third parties and other entities with
                  whom we share personal information; and{" "}
                </li>
                <li>
                  {" "}
                  Access the specific pieces of personal information we have
                  collected about you.{" "}
                </li>
              </ul>

              <p>
                To request this information, please email your name, email
                address, California postal address as well as your loan ID, if
                you have one to{" "}
                <a
                  style={{ fontWeight: "bold" }}
                  href="mailto:CCPA@pavecredit.com"
                >
                  CCPA@pavecredit.com
                </a>
                . You may also exercise your right by calling (855) 801-7283.{" "}
              </p>
              <ul>
                <li>
                  {" "}
                  Right to deletion. In some circumstances, you may ask us to
                  delete your personal information. To request deletion of your
                  information, please email your name, email address, California
                  postal address as well as your loan ID, if you have one to{" "}
                  <a
                    style={{ fontWeight: "bold" }}
                    href="mailto:CCPA@pavecredit.com"
                  >
                    CCPA@pavecredit.com
                  </a>{" "}
                  You may also exercise your right by calling (855) 801-7283.{" "}
                </li>
                <li>
                  {" "}
                  Right to opt out of sales. We may share your personal
                  information with third parties in ways that may constitute a
                  “sale” under CCPA. You may request that we not “sell” your
                  personal information on a going forward basis. To do so,
                  please email your name, email address, California postal
                  address as well as your loan ID, if you have one to{" "}
                  <a
                    style={{ fontWeight: "bold" }}
                    href="mailto:CCPA@pavecredit.com"
                  >
                    CCPA@pavecredit.com
                  </a>
                  . You may also exercise your right by calling (855) 801-7283.
                  If you reengage with us after opting out of the “sale” of your
                  personal information, such as obtaining a new loan, signing up
                  for a newsletter and/or entering a sweepstakes or contest your
                  personal information will be collected and used in accordance
                  with this Privacy Notice.{" "}
                </li>
                <li>
                  {" "}
                  Right to be free from discrimination. You may exercise any of
                  the above rights without fear of being discriminated against.
                  We are permitted to provide a different price or rate to you
                  if the difference is directly related to the value provided to
                  you by your data.{" "}
                </li>
              </ul>
              <p>
                For any of the above-mentioned rights, you may designate an
                authorized agent to make a request on your behalf. In the
                request, you or your authorized agent must provide including
                information sufficient for us to confirm the identity of an
                authorized agent. We are required to verify that your agent has
                been properly authorized to request information on your behalf
                and this may take additional time to fulfill your request.{" "}
              </p>
              <p>
                We will use the information you provide to make your CCPA rights
                requests to verify your identity, identify the personal
                information we may hold about you and act upon your request. We
                strongly recommend that you submit the email and postal address
                that you used when you created accounts or signed up for a
                newsletter. If you are a California resident and have any
                questions regarding your CCPA rights under this Privacy Notice,
                please contact us at{" "}
                <a
                  style={{ fontWeight: "bold" }}
                  href="mailto:CCPA@pavecredit.com"
                >
                  CCPA@pavecredit.com
                </a>
                .{" "}
              </p>
              <p>
                To view Salt Point Finance dba Pave Credit’s full privacy policy{" "}
                <a
                  href="/docs/Salt%20Point%20Finance%20dba%20Pave%20Credit_Privacy%20Policy_compressed.pdf"
                  target="_blank"
                >
                  click here
                </a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
    <Footer />
  </>
)

export default CcpaInfo
